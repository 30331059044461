import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function TheATeam() {
  return (
    <Layout>
      <Helmet title="The A Team | Ed Sheeran" />

      <h1>The A Team</h1>

      <h2>Ed Sheeran</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 88</h4>

      <p>Non-ASCAP</p>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p><b>G   G   G/F# Em  Cadd9  G</b></p>
        </Verse>
        <Verse>
          <p><Ch>G</Ch>White lips, pale face, breathing in <Ch>G/F#</Ch>snowflakes<Ch>Em</Ch></p>
          <p>Burnt <Ch>Cadd0</Ch>lungs, sour <Ch>G</Ch>taste <Ch>A7sus4</Ch></p>
          <p><Ch>G</Ch>Light’s gone, day’s end, struggling to <Ch>G/F#</Ch>pay <Ch>Em</Ch>rent</p>
          <p>Long nights,<Ch>Cadd9</Ch> strange <Ch>G</Ch>men</p>
        </Verse>
        <PreChorus>
          <p><Ch>Am7</Ch>And they say she’s in the Class A <Ch>C</Ch>Team, stuck in her day<Ch>G</Ch>dream</p>
          <p>Been this way since <Ch>D/F#</Ch>18, but lately her <Ch>Am7</Ch>face seems, slowly sinking, <Ch>C</Ch>wasting</p>
          <p>Crumbling like <Ch>G</Ch>pastries, and they scream the <Ch>D/F#</Ch>worst things in life come free to us</p>
        </PreChorus>
        <Chorus>
          <p>Cos she's <Ch>Em</Ch>just under the <Ch>Cadd9</Ch>upperhand, <Ch>G</Ch>and go mad for a couple of grams</p>
          <p><Ch>Em</Ch>And she don’t want to go <Ch>Cadd9</Ch>outside <Ch>G</Ch>tonight</p>
          <p>And in a <Ch>Em</Ch>pipe she flies to the <Ch>Cadd9</Ch>Motherland, <Ch>G</Ch>or sells love to another man</p>
          <p><Ch>Em</Ch>It’s too <Ch>Cadd9</Ch>cold out<Ch>G</Ch>side for <Ch>D/F#</Ch>angels to <Ch>Em</Ch>fly<Ch>C</Ch>...<Ch>G</Ch></p>
          <p>for angels to <Ch>Em</Ch>fly <Ch>Cadd9</Ch>...<Ch>G</Ch>...<Ch>G/F#</Ch></p>
        </Chorus>
        <Verse>
          <p><Ch>G</Ch>Ripped gloves, raincoat, tried to swim and <Ch>G/F#</Ch>stay afloat<Ch>Em</Ch></p>
          <p>Dry <Ch>Cadd9</Ch>house, wet <Ch>G</Ch>clothes<Ch>A7sus4</Ch></p>
          <p><Ch>G</Ch>Loose change, bank notes, weary-eyed, <Ch>G/F#</Ch>dry throat<Ch>Em</Ch></p>
          <p>Call <Ch>Cadd9</Ch>girl, no <Ch>G</Ch>phone</p>
        </Verse>

        <PreChorus>
          <p><Ch>Am7</Ch>And they say she’s in the Class A <Ch>C</Ch>Team, stuck in her day<Ch>G</Ch>dream</p>
          <p>Been this way since <Ch>D/F#</Ch>18, but lately her <Ch>Am7</Ch>face seems, slowly sinking, <Ch>C</Ch>wasting</p>
          <p>Crumbling like <Ch>G</Ch>pastries, and they scream the <Ch>D/F#</Ch>worst things in life come free to us</p>
        </PreChorus>
        <Chorus>
          <p>Cos she's <Ch>Em</Ch>just under the <Ch>Cadd9</Ch>upperhand, <Ch>G</Ch>and go mad for a couple of grams</p>
          <p><Ch>Em</Ch>And she don’t want to go <Ch>Cadd9</Ch>outside <Ch>G</Ch>tonight</p>
          <p>And in a <Ch>Em</Ch>pipe she flies to the <Ch>Cadd9</Ch>Motherland, <Ch>G</Ch>or sells love to another man</p>
          <p><Ch>Em</Ch>It’s too <Ch>Cadd9</Ch>cold out<Ch>G</Ch>side for <Ch>D/F#</Ch>angels to <Ch>Am7</Ch>fly</p>
        </Chorus>
        <Bridge>
          <p><Ch>Am7</Ch>An angel will <Ch>C</Ch>die, covered in <Ch>Em</Ch>white</p>
          <p>Closed <Ch>G/F#</Ch>eye and <Ch>G</Ch>hoping for a better life</p>
          <p><Ch>Am7</Ch>This time, we’ll <Ch>C*</Ch>fade out <Ch>Dadd4</Ch>tonight, <Ch>Em</Ch>straight <Ch>C*</Ch>down <Ch>G*</Ch>the line<Ch>D/F#</Ch></p>
          <p>Straight <Ch>Em</Ch>down <Ch>C</Ch>the <Ch>G</Ch>line<Ch>D/F#</Ch></p>
        </Bridge>
        <PreChorus>
          <p><Ch>Am7</Ch>And they say she’s in the Class A <Ch>C</Ch>Team, stuck in her day<Ch>G</Ch>dream</p>
          <p>Been this way since <Ch>D/F#</Ch>18, but lately her <Ch>Am7</Ch>face seems, slowly sinking, <Ch>C</Ch>wasting</p>
          <p>Crumbling like <Ch>G</Ch>pastries, and they scream the <Ch>D/F#</Ch>worst things in life come free to us</p>
        </PreChorus>
        <Chorus>
          <p>Cos she's <Ch>Em</Ch>just under the <Ch>Cadd9</Ch>upperhand, <Ch>G</Ch>and go mad for a couple of grams</p>
          <p><Ch>Em</Ch>And she don’t want to go <Ch>Cadd9</Ch>outside <Ch>G</Ch>tonight</p>
          <p>And in a <Ch>Em</Ch>pipe she flies to the <Ch>Cadd9</Ch>Motherland, <Ch>G</Ch>or sells love to another man</p>
          <p><Ch>Em</Ch>It’s too <Ch>Cadd9</Ch>cold out<Ch>G</Ch>side for <Ch>D/F#</Ch>angels to <Ch>Am7</Ch>fly</p>
          <p>Flyyyy, flyyyy, angels to fly, fly, flyyy</p>
          <p>Or angels to die</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
